<template>
  <div class="cropper-and-upload">
    <el-upload ref="cropperUpload" :action="uploadUrl" :file-list="fileList" :headers="headers" :auto-upload="false" :multiple="limit != 1" :limit="limit" name="files" accept=".jpg,.jpeg,.png" list-type="picture-card" :disabled="disabled" :on-change="onChange" :before-upload="beforeUpload" :on-remove="handleRemove" :on-success="handleSuccess" :on-error="handleError" :on-preview="handlePictureCardPreview">
      <i slot="default" class="el-icon-plus"></i>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
      <div slot="tip" class="el-upload__tip">
        只能上传jpg/jpeg/png格式图片。
      </div>
    </el-upload>
    <el-button v-if="fileList.length && !disabled" type="primary" :loading="loading" @click="startCropper">裁剪图片</el-button>
    <el-button v-if="fileList.length && !disabled" type="success" :loading="loading" @click="submitUpload">不裁剪直接上传图片</el-button>
    <el-dialog title="裁剪图片" :visible.sync="dialogVisible" width="80%" :append-to-body="true">
      <div class="cropper-flex">
        <div class="cropper-container">
          <vueCropper ref="cropper" :img="currentFile.url" :outputSize="option.outputSize" :outputType="option.outputType" :autoCrop="option.autoCrop" :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" :canMoveBox="option.canMoveBox" :canMove="option.canMove" :fixed="option.fixed" :fixedBox="option.fixedBox" :original="option.original" :centerBox="option.centerBox" :fixedNumber="size" :enlarge="option.enlarge" :infoTrue="option.infoTrue" :full="option.full" :high="option.high" :max="option.max" :mode="option.mode" @realTime="realTime"></vueCropper>
        </div>
        <div class="cropper-option">
          <div class="cropper-preview">
            <div :style="previewStyle">
              <div :style="previews.div">
                <img :src="previews.url" :style="previews.img" />
              </div>
            </div>
          </div>
          <div class="cropper-btn">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-button icon="el-icon-zoom-in" @click="changeScale(1)">放大</el-button>
              </el-col>
              <el-col :span="6">
                <el-button icon="el-icon-zoom-out" @click="changeScale(-1)">缩小</el-button>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-button icon="el-icon-refresh-left" @click="rotateLeft">向左旋转</el-button>
              </el-col>
              <el-col :span="6">
                <el-button icon="el-icon-refresh-right" @click="rotateRight">向右旋转</el-button>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-button type="danger" icon="el-icon-delete" @click="refreshCrop">清除改动</el-button>
              </el-col>
              <el-col :span="6">
                <el-button type="success" @click="finish">裁剪完成 下一张</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="image-list">
        <img :class="{ active: activeImageIndex == index }" v-for="(file, index) in fileList" :key="index" :src="file.cropperUrl ? file.cropperUrl : file.url" :alt="file.name" @click="activeImageIndex = index" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveCropper">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogPictureVisible" :append-to-body="true">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
export default {
  components: {
    VueCropper,
  },
  props: {
    /**
     * 截取比例 默认15/11轮播图尺寸
     */
    size: {
      type: Array,
      default: () => [15, 11],
    },
    /**
     * 比例倍数 用来生成截图框宽高
     */
    multiple: {
      type: Number,
      default: () => 46,
    },
    /**
     * 最大允许上传个数
     */
    limit: {
      type: Number,
      default: () => 9,
    },
    /**
     * 是否能排序
     */
    sortable: {
      type: Boolean,
      default: () => true,
    },
    /**
     * 图片列表，用于反显
     */
    list: {
      type: Array,
      default: () => [],
    },
    /**
     * 是否禁用
     */
    disabled: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      headers: {
        Authorization: sessionStorage.getItem("Authorization"),
      },
      uploadUrl: this.$axios.defaults.basePath + "/image/AliYunImgUpload",
      fileList: [],
      option: {
        outputSize: 1,
        outputType: "png",
        autoCrop: true,
        autoCropWidth: this.size[0] * this.multiple,
        autoCropHeight: this.size[1] * this.multiple,
        canMove: true,
        canMoveBox: false,
        fixed: true,
        fixedBox: false,
        original: false,
        centerBox: false,
        fixedNumber: [15, 11],
        enlarge: 2,
        infoTrue: true,
        full: false,
        high: true,
        max: 99999,
        mode: "contain",
      },
      dialogVisible: false,
      previewStyle: {},
      previews: {},
      activeImageIndex: 0,
      dialogPictureVisible: false,
      dialogImageUrl: "",
      loading: false,
      removeIndex: null,
    };
  },
  computed: {
    currentFile() {
      if (this.fileList && this.fileList.length)
        return this.fileList[this.activeImageIndex];
      else return {};
    },
  },
  watch: {
    list: {
      handler() {
        if (this.list.length) {
          let newArr = []
          this.list.forEach(item => {
            let json = { url: '' }
            if (typeof (item) == 'string') {
              json.url = item
            } else if (item.url) {
              json = item
            }
            newArr.push(json)
          })
          this.fileList = newArr.map((item) =>
            Object.assign({}, item, {
              response: item.raw ? null : item.url,
            })
          );
        } else {
          this.fileList = []
        }

      },
      immediate: true,
    },
  },
  methods: {
    onChange(file, fileList) {
      console.log(file, fileList)
      this.fileList = fileList;
      // this.$emit("change", fileList);
    },
    beforeUpload(file) {
      const imageConversion = require("image-conversion");
      console.log('压缩前' + (file.size / 1024) + "K");
      this.loading = true;
      imageConversion.compressAccurately(file, 100).then(res => {
        this.loading = false;
        console.log('压缩后' + (res.size / 1024) + "K");
        // console.log(res);
      })
    },

    success(fileList = this.fileList) {
      let imageList = [];
      fileList.forEach(item=>{
        if(item.response && item.response.data){
          imageList.push(item.response.data[0])
        }
      })
      this.$emit("success", imageList, this.removeIndex);
    },
    handleLeft(file) {
      const index = this.fileList.findIndex((_file) => _file.uid == file.uid);
      if (index > -1) {
        this.fileList.splice(index, 1);
        if (index > 0) this.fileList.splice(index - 1, 0, file);
        else this.fileList.splice(this.fileList.length, 0, file);
        this.success();
      }
    },
    handleRight(file) {
      const index = this.fileList.findIndex((_file) => _file.uid == file.uid);
      if (index > -1) {
        this.fileList.splice(index, 1);
        if (index < this.fileList.length)
          this.fileList.splice(index + 1, 0, file);
        else this.fileList.splice(0, 0, file);
        this.success();
      }
    },
    changeScale(num = 1) {
      this.$refs.cropper.changeScale(num);
    },
    refreshCrop() {
      this.$refs.cropper.refresh();
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    handleRemove(file) {
      console.log(file)
      this.fileList.forEach((item, index) => {
        if (file.name == item.name) {
          this.removeIndex = index
        }
      })
      this.fileList = this.fileList.filter((_file) => _file.uid != file.uid);
      if (this.fileList.length == 0) {
        this.removeIndex = null
      }
      this.$emit("change", this.fileList, this.removeIndex);
      this.success();
    },
    handleSuccess(response, file, fileList) {
      console.log(fileList)
      this.success(fileList);
      this.loading = false;
    },
    handleError() {
      this.loading = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogPictureVisible = true;
    },
    submitUpload() {
      const imageList = this.fileList.filter(
        (_file) => _file && _file.raw && !_file.response
      );
      console.log(imageList)
      if (imageList.length) {
        this.loading = true;
        this.$refs.cropperUpload.submit();
      }
    },
    startCropper() {
      this.dialogVisible = true;
      // if (this.fileList && this.fileList.length)

      // this.imageUrlToBase64(this.currentFile.url)
      // this.$set(this.option, "img", baseUrl);
      // this.fileList = this.fileList.filter(
      //   (_file) => _file && _file.raw && !_file.response
      // );
      console.log(this.fileList)
      this.$nextTick(() => {
        this.$refs.cropper.startCrop();
      });
    },
    saveCropper() {
      console.log(this.fileList)
      this.fileList = this.fileList.map((file) => {
        if (file.cropperRaw || file.raw) {
          file.raw = file.cropperRaw || file.raw;
          file.raw.uid = file.uid;
          file.url = file.cropperUrl || file.url;
          delete file.cropperRaw;
          delete file.cropperUrl;
          return file;
        }
      });
      console.log(this.fileList)
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.submitUpload();
      });
    },
    realTime(data) {
      var previews = data;
      var h = 0.5;

      this.previewStyle = {
        width: previews.w + "px",
        height: previews.h + "px",
        overflow: "hidden",
        margin: "0",
        zoom: h,
        background: "#ccc",
      };
      this.previews = data;
    },
    finish(next = false) {
      this.$refs.cropper.getCropBlob((data) => {
        const currentFile = this.fileList[this.activeImageIndex];
        const file = new window.File([data], this.currentFile.name, {
          type: data.type,
        });
        this.$set(currentFile, "response", null);
        this.$set(currentFile, "status", "ready");
        this.$set(currentFile, "cropperRaw", file);

        let reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = (e) => {
          this.$set(currentFile, "cropperUrl", e.target.result);
        };
        console.log(this.currentFile)
        if (next)
          this.activeImageIndex =
            this.activeImageIndex < this.fileList.length - 1
              ? this.activeImageIndex + 1
              : 0;
      });
    },
    getBase64(imgUrl) {
      let basePath = '';
      window.URL = window.URL || window.webkitURL;
      var xhr = new XMLHttpRequest();
      xhr.open("get", imgUrl, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (this.status == 200) {
          //得到一个blob对象
          var blob = this.response;
          // 至关重要
          let oFileReader = new FileReader();
          oFileReader.onloadend = function (e) {
            console.log(e.target.result)
            // 此处拿到的已经是base64的图片了,可以赋值做相应的处理
          }
          oFileReader.readAsDataURL(blob);
        }
      }
      xhr.send();
    },
    imageUrlToBase64(imageUrl) {
      //一定要设置为let，不然图片不显示
      let image = new Image();
      //解决跨域问题
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = imageUrl
      let that = this;
      //image.onload为异步加载
      image.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        var quality = 0.8;
        //这里的dataurl就是base64类型
        var dataURL = canvas.toDataURL("image/jpeg", quality);
        console.log(dataURL)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cropper-and-upload {
  .cropper-flex {
    display: flex;
    justify-content: flex-start;
  }
}
::v-deep .cropper-flex {
  display: flex;
  justify-content: flex-start;
  .cropper-container {
    width: 800px;
  }
  .cropper-option {
    width: calc(100% - 832px);
    height: 600px;
    padding: 0 0 0 32px;
  }

  .cropper-preview {
    width: 100%;
    height: 300px;
  }

  .cropper-btn {
    margin-top: 30px;
  }
}
::v-deep .image-list {
  margin-top: 30px;
  img {
    width: 150px;
    height: 110px;
    margin: 5px 10px;
    object-fit: contain;
    background-position: 0 0, 10px 10px;
    background-size: 20px 20px;
    background-image: linear-gradient(
        45deg,
        #eee 25%,
        transparent 0,
        transparent 75%,
        #eee 0,
        #eee
      ),
      linear-gradient(45deg, #eee 25%, #fff 0, #fff 75%, #eee 0, #eee);
  }

  .active {
    border: 3px solid #409eff;
    box-sizing: border-box;
  }
}

::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 9.25rem;
  height: 9.25rem;
  object-fit: contain;
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>
